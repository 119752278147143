// variaveis
@import "./helpers/variables.scss";

// mixins
@import "./helpers/mixins";

// functions
@import "./helpers/functions";

// placeholders
@import "./helpers/placeholders";

.login {
  background-color: $colorPrimary;
  min-height: 100vh;
  padding: 20px;

  .v-btn {
    min-width: 120px;
    height: 40px;;
  }

  &-box {
    max-width:  350px;
    margin: 5% auto 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    .logo {
      max-width: 200px;
      margin-bottom: 20px;
    }
  }

  &-esqueci-senha {
    color: $colorPrimary;
    text-decoration: none;
  }
}

.card-login {
  width: 100%;

  &-texto {
    margin-top: 10px;
    margin-bottom: 0;
    text-align: center;
    padding: 0.5rem;
    font-weight: bold;
  }

  .v-input__prepend-outer {
    position: absolute;
    left: 5px;
  }
}

.dialog-recover-password {
  color: red($color: #000000);
  &-titulo-text{
      justify-content: center;
      display: flex;
      width: 100%;
  }
}  